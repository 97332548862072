var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","data-cy":"open-problem-menu-btn"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}]),model:{value:(_setup.isMenuOpen),callback:function ($$v) {_setup.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c('v-list',[(
          _vm.isTeacherUser &&
          [_setup.PageView.LESSON_VIEW, _setup.PageView.SEARCH_RESULTS].includes(_setup.parentPage)
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_setup.saveToMyProblemSets = true}}},[_vm._v(" SAVE TO MY PROBLEM SETS ")]):_vm._e(),(_vm.isTeacherUser || _vm.isTrustedBuilderUser)?_c('v-list-item',{staticClass:"text-uppercase",attrs:{"link":"","href":_setup.previewLink,"target":"blank","data-cy":"preview-ps"},on:{"click":_setup.handlePreviewClick}},[_vm._v(" PREVIEW AS STUDENT "),_c(_setup.PreviewInformationPopup)],1):_vm._e(),(
          _vm.isTeacherUser &&
          (_setup.parentPage === _setup.PageView.EDIT_MY_PROBLEM_SET ||
            _setup.parentPage === _setup.PageView.LESSON_VIEW)
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_setup.editStudentSupports = true}}},[_vm._v(" EDIT STUDENT SUPPORTS ")]):_vm._e(),(_setup.parentPage === _setup.PageView.SEARCH_RESULTS && !_setup.isPartProblem)?_c('v-list-item',{attrs:{"link":"","data-cy":"go-to-ps"},on:{"click":function($event){_setup.navigateToProblemSet = true}}},[_vm._v(" GO TO PROBLEM SET ")]):_vm._e(),(
          _setup.parentPage === _setup.PageView.EDIT_MY_PROBLEM_SET &&
          !_setup.isPartProblem &&
          _setup.parent?.permissions.includes(_setup.AclPermissionType.UPDATE)
        )?_c('v-list-item',{attrs:{"link":"","disabled":_vm.isFirst,"data-cy":"move-up"},on:{"click":function($event){return _setup.emit('move-up')}}},[_vm._v(" MOVE UP ")]):_vm._e(),(
          _setup.parentPage === _setup.PageView.EDIT_MY_PROBLEM_SET &&
          !_setup.isPartProblem &&
          _setup.parent?.permissions.includes(_setup.AclPermissionType.UPDATE)
        )?_c('v-list-item',{attrs:{"link":"","data-cy":"move-down","disabled":_vm.isLast},on:{"click":function($event){return _setup.emit('move-down')}}},[_vm._v(" MOVE DOWN ")]):_vm._e(),(
          _setup.parentPage === _setup.PageView.EDIT_MY_PROBLEM_SET &&
          !_setup.isPartProblem &&
          _setup.parent?.permissions.includes(_setup.AclPermissionType.UPDATE)
        )?[_c('v-divider'),_c('v-list-item',{attrs:{"link":"","data-cy":"remove-problem"},on:{"click":function($event){return _setup.emit('remove')}}},[_vm._v(" REMOVE PROBLEM ")])]:_vm._e()],2)],1),_c(_setup.SaveToMyProblemSetsDialog,{attrs:{"selectedTree":_setup.selectedTree},model:{value:(_setup.saveToMyProblemSets),callback:function ($$v) {_setup.saveToMyProblemSets=$$v},expression:"saveToMyProblemSets"}}),_c(_setup.StudentSupportsDialog,{attrs:{"problem":_setup.problem},model:{value:(_setup.editStudentSupports),callback:function ($$v) {_setup.editStudentSupports=$$v},expression:"editStudentSupports"}}),_c(_setup.GoToParentProblemSetDialog,{attrs:{"ceri":_setup.problem.xref,"source":_setup.EXPLORE_CONTENT,"returnText":"Search Results"},model:{value:(_setup.navigateToProblemSet),callback:function ($$v) {_setup.navigateToProblemSet=$$v},expression:"navigateToProblemSet"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }