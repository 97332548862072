
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Class } from '@/domain/Class';
import {
  AssignmentDefinition,
  AssignmentStatus,
  AssignmentSort,
  AssignmentSortByField,
} from '@/domain/Assignment';
import AssignmentView from '@/components/MyAssignments/AssignmentView.vue';
import NoAssignmentView from '@/components/MyAssignments/NoAssignmentView.vue';
import ScrollObserver from '@/components/base/ScrollObserver.vue';
import {
  AssignmentFilterAndSortParams,
  EditableAssignmentFields,
  getAssignmentDefinitions,
} from '@/api/core/assignment.api';
import dayjs from 'dayjs';
import PrintProblemSetsView from '@/components/Print/PrintProblemSetsView.vue';
import { PrintDefinition } from '@/domain/PrintDefinition';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { debounce, isEqual } from 'lodash';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { DefinitionInclude } from '@/api/core/base.api';

@Component({
  components: {
    AssignmentView,
    NoAssignmentView,
    ScrollObserver,
    PrintProblemSetsView,
  },
})
export default class MyAssignmentsPage extends Vue {
  statusOptions = [
    { text: 'Upcoming', value: AssignmentStatus.UPCOMING },
    { text: 'Released', value: AssignmentStatus.RELEASED },
    { text: 'Past Due', value: AssignmentStatus.PAST_DUE },
  ];
  // FIXME: Confirm these sortBy field values
  sortByOptions = [
    {
      text: 'Due Date (Ascending)',
      value: { attribute: 'dueDate', sortBy: '+DUE_DATE' },
    },
    {
      text: 'Due Date (Descending)',
      value: { attribute: 'dueDate', sortBy: '-DUE_DATE' },
    },
    {
      text: 'Release Date (Ascending)',
      value: { attribute: 'releaseDate', sortBy: '+RELEASE_DATE' },
    },
    {
      text: 'Release Date (Descending)',
      value: { attribute: 'releaseDate', sortBy: '-RELEASE_DATE' },
    },
  ];

  isDownloadingAssignments = false;
  hasDownloadedAssignments = false;
  isDownloadingMore = false;
  assignments: AssignmentDefinition[] = [];

  // Paging
  limit = 20;
  nextPageToken: string | undefined = undefined;

  // Search
  controller: AbortController | undefined = undefined;

  printData: PrintDefinition = {
    problemSet: {} as ProblemSetDefinition,
    name: '',
    className: '',
    status: '',
    date: '',
    contentType: 'ASSIGNMENT',
  };

  //////////////
  // Computed //
  //////////////

  /**
   * Store
   */
  get courses(): Array<Class> {
    return this.$store.state.classList.classes;
  }

  get courseXrefs(): Array<string> {
    return this.courses.map((c) => c.id);
  }

  /**
   * Display & Rendering
   */
  get filters(): AssignmentFilterAndSortParams {
    const params: AssignmentFilterAndSortParams = {
      sortBy: this.selectedSort.sortBy,
      limit: this.limit,
      include: [DefinitionInclude.SETTINGS],
    };

    if (this.selectedCourses.length) {
      params.courseXrefs = this.selectedCourses;
    }

    if (this.selectedStatuses.length) {
      //We want to consider putting this in a better long term location. We want our statuses to match the api
      // release/due date filters
      let releaseDate = '';
      let dueDate = '';
      // let assignDate = null;
      let now = dayjs().valueOf();
      for (let status of this.selectedStatuses) {
        switch (status) {
          case AssignmentStatus.UPCOMING:
            if (releaseDate) {
              releaseDate += '[OR]';
            }
            releaseDate += `GT:${now}`;
            break;

          case AssignmentStatus.RELEASED:
            if (releaseDate) {
              releaseDate += '[OR]';
            }
            releaseDate += `LT:${now}`;

            break;
          case AssignmentStatus.PAST_DUE:
            dueDate += `LT:${now}`;

            break;
        }
      }
      if (releaseDate.length) {
        params.releaseDate = releaseDate;
      }
      if (dueDate.length) {
        params.dueDate = dueDate;
      }
    }

    return params;
  }

  get selectedCourses(): string[] {
    const courseList = this.$route.query.courses;
    if (courseList) {
      return (courseList as string).split(',');
    } else {
      return [];
    }
  }

  set selectedCourses(courses: string[] | undefined) {
    if (!isEqual(courses, this.selectedCourses)) {
      const param = courses?.length ? courses.join(',') : undefined;
      // Update the URL.
      this.$router.replace({
        query: {
          ...this.$route.query,
          courses: param,
        },
      });
    }
  }

  get selectedStatuses(): AssignmentStatus[] {
    const statusList = this.$route.query.statuses;
    if (statusList) {
      return (statusList as string).split(',') as AssignmentStatus[];
    } else {
      return [];
    }
  }

  set selectedStatuses(statuses: AssignmentStatus[]) {
    if (!isEqual(statuses, this.selectedStatuses)) {
      const param = statuses?.length ? statuses.join(',') : undefined;
      // Update the URL.
      this.$router.replace({
        query: {
          ...this.$route.query,
          statuses: param,
        },
      });
    }
  }

  get selectedSort(): AssignmentSort {
    const field = this.$route.query.sortAttribute as keyof AssignmentDefinition;
    const direction = this.$route.query.sortDirection as AssignmentSortByField;
    if (field && direction) {
      return {
        attribute: field,
        sortBy: direction,
      };
    }
    return { attribute: 'releaseDate', sortBy: '-RELEASE_DATE' };
  }

  set selectedSort(sort: AssignmentSort) {
    if (!isEqual(sort, this.selectedSort)) {
      // Update the URL.
      this.$router.replace({
        query: {
          ...this.$route.query,
          sortAttribute: sort.attribute,
          sortDirection: sort.sortBy,
        },
      });
    }
  }

  downloadAssignments(): void {
    // Loading state to indicate loading initial set assignments
    this.isDownloadingAssignments = true;
    this.hasDownloadedAssignments = false;
    // Cancel previous request.
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();
    // Clear out prior results
    this.assignments = [];
    this.nextPageToken = undefined;
    getAssignmentDefinitions(this.filters, this.controller)
      .then((assignmentList) => {
        this.assignments = assignmentList.data;
        this.nextPageToken = assignmentList.nextPageToken;
        this.hasDownloadedAssignments = true;
      })
      .finally(() => {
        this.isDownloadingAssignments = false;
      });
  }

  updateAssignment(
    assignment: AssignmentDefinition,
    modifiedFields: EditableAssignmentFields
  ): void {
    // Modified assignment
    if (modifiedFields.name) {
      assignment.name = modifiedFields.name;
    }

    if (modifiedFields.releaseDate) {
      assignment.releaseDate = modifiedFields.releaseDate;
    } else if (modifiedFields.releaseDate === null) {
      // Removed? Set to now.
      assignment.releaseDate = dayjs().valueOf();
    } else {
      // Not modified if not included. So nothing to update.
    }

    if (modifiedFields.dueDate) {
      assignment.dueDate = modifiedFields.dueDate;
    } else if (modifiedFields.dueDate === null) {
      // We do have a due date set to null
      // Remove/delete due date?
      assignment.dueDate = null;
    } else {
      // Not modified if not included. So nothing to update.
    }
  }

  downloadNextPage(): void {
    if (!this.isDownloadingMore && this.nextPageToken) {
      this.isDownloadingMore = true;
      trackMixpanel(EventType.myAssignmentsDownloadMore);
      getAssignmentDefinitions(
        {
          ...this.filters,
          nextPageToken: this.nextPageToken,
        },
        this.controller
      )
        .then((assignmentList) => {
          this.assignments.push(...assignmentList.data);
          this.nextPageToken = assignmentList.nextPageToken;
        })
        .finally(() => {
          this.isDownloadingMore = false;
        });
    }
  }

  created(): void {
    // Should already be logged in to be here.

    // Download classes if not already
    this.$store.dispatch('classList/requestClasses');

    if (this.isLtiUser) {
      // Launch context should already be downloaded at this point.
      const contextXref = this.$store.state.lti.launch?.contextXref;
      if (contextXref) {
        this.selectedCourses = [contextXref];
        // Download context assignments only.
        this.downloadAssignments();
      } else {
        // Missing context to download assignments from so nothing more we can do.
        this.hasDownloadedAssignments = true;
      }
    } else {
      // Download assignments.
      this.downloadAssignments();
    }
  }

  print(printData: PrintDefinition): void {
    this.printData = printData;
  }

  /////////////
  // Watcher //
  /////////////

  @Watch('$route.query')
  onQueryChange(): void {
    this.downloadAssignments();
  }

  mounted(): void {
    this.trackMyAssignmentsLoaded();
  }

  trackMyAssignmentsLoaded(): void {
    trackMixpanel(EventType.navMyAssignmentsLoaded);
  }

  assignmentFiltersChangedEvent = debounce(() => {
    trackMixpanel(EventType.myAssignmentFiltersUsed, {
      selectedClasses: this.selectedCourses,
      selectedStatuses: this.selectedStatuses,
      selectedSort: this.selectedSort.sortBy,
    });
  }, 0.1);

  @Watch('selectedClasses')
  onSelectedClassesChange() {
    this.assignmentFiltersChangedEvent();
  }

  @Watch('selectedStatuses')
  onSelectedStatusesChange() {
    this.assignmentFiltersChangedEvent();
  }

  @Watch('selectedSort')
  onSelectedSortChange() {
    this.assignmentFiltersChangedEvent();
  }
}
