
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DeleteSupportDialog extends Vue {
  @Prop() value: boolean;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  deleteSupport() {
    this.showDialog = false;
    this.$emit('delete-support');
  }
}
