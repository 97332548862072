import { useStore } from '@/store';

const store = useStore();

//TODO: Consider a different name
export function notify(
  notification: string | Notification | string[] | Notification[]
) {
  if (Array.isArray(notification)) {
    notification.forEach((notificationInstance: string | Notification) => {
      store.commit('notifications/addNotification', notificationInstance);
    });
  } else {
    store.commit('notifications/addNotification', notification);
  }
}
