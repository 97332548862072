var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-cy":"folder-menu-btn"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"text-body-2"},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _setup.emit('open', _setup.folder.xref)}}},[_vm._v(" Open contents ")]),(_setup.folder.xref != _setup.EXPLORE_CONTENT)?[_c(_setup.EditFolderDialog,{attrs:{"mode":_setup.EditMode.UPDATE,"path":_vm.path}}),(_setup.parentPage == _setup.PageView.MAIN_HIERARCHY)?_c(_setup.CopyFolderDialog,{attrs:{"path":_vm.path}}):_vm._e(),(
            !_setup.isRoot && _setup.parent?.permissions.includes(_setup.AclPermissionType.UPDATE)
          )?_c(_setup.MoveToFolderDialog,{attrs:{"path":_vm.path}}):_vm._e(),(_setup.parentPage == _setup.PageView.MAIN_HIERARCHY)?_c(_setup.CopyTextDialog,{attrs:{"title":"Obtain ID","description":"Folder ID","content":_setup.folder.xref}}):_vm._e(),(_setup.parentPage == _setup.PageView.MAIN_HIERARCHY)?_c(_setup.CopyTextDialog,{attrs:{"title":"Generate Link","description":"Folder URL","content":_setup.url}}):_vm._e(),(
            _setup.parentPage == _setup.PageView.MAIN_HIERARCHY &&
            (_vm.isServiceAdminUser ||
              _vm.isContentAdminUser ||
              _vm.getCurrentUser.xref == _setup.folder.ownerXref)
          )?_c(_setup.PermissionsDialog,{attrs:{"path":_vm.path}}):_vm._e()]:_vm._e(),(_setup.folder.permissions.includes(_setup.AclPermissionType.UPDATE))?[_c('v-divider',{staticClass:"my-2"}),(!_setup.folder.properties.OPEN_IN_LESSON_PAGE)?_c(_setup.EditFolderDialog,{attrs:{"mode":_setup.EditMode.CREATE,"path":_vm.path}}):_vm._e(),(_setup.parentPage == _setup.PageView.MAIN_HIERARCHY)?_c('v-list-item',{attrs:{"link":"","data-cy":"add-new-problem-set-btn"},on:{"click":function($event){_setup.createPSDialog = true}}},[_vm._v(" Add new problem set ")]):_vm._e()]:_vm._e(),(
          !_setup.isRoot && _setup.folder.permissions.includes(_setup.AclPermissionType.DELETE)
        )?[_c('v-divider',{staticClass:"my-2"}),_c(_setup.DeleteFolderDialog,{attrs:{"path":_vm.path}})]:_vm._e()],2)],1),_c(_setup.CreateProblemSetDialog,{on:{"created":_setup.addProblemSet},model:{value:(_setup.createPSDialog),callback:function ($$v) {_setup.createPSDialog=$$v},expression:"createPSDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }