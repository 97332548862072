import { computed } from 'vue';
import { useVuetify } from './useVuetify';

export function useTutorHelper() {
  const vuetify = useVuetify();

  const firstTuteeColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.primary.lighten1;
  });

  const firstTuteeTextColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.primary.darken4;
  });

  const secondTuteeColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.yellow.lighten1;
  });

  const secondTuteeTextColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.primary.darken4;
  });

  const thirdTuteeColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.primary.darken1;
  });

  const thirdTuteeTextColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.neutral.lighten5;
  });

  const fourthTuteeColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.neutral.lighten1;
  });

  const fourthTuteeTextColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.primary.darken4;
  });

  const labelColors = computed((): Array<string> => {
    return [
      firstTuteeColor.value,
      secondTuteeColor.value,
      thirdTuteeColor.value,
      fourthTuteeColor.value,
    ];
  });

  const textColors = computed((): Array<string> => {
    return [
      firstTuteeTextColor.value,
      secondTuteeTextColor.value,
      thirdTuteeTextColor.value,
      fourthTuteeTextColor.value,
    ];
  });

  const classAverageColor = computed((): string => {
    // eslint-disable-next-line
    // @ts-ignore
    return vuetify.theme.themes.light.neutral.darken3;
  });

  return {
    firstTuteeColor,
    firstTuteeTextColor,
    secondTuteeColor,
    secondTuteeTextColor,
    thirdTuteeColor,
    thirdTuteeTextColor,
    fourthTuteeColor,
    fourthTuteeTextColor,
    labelColors,
    textColors,
    classAverageColor,
  };
}
