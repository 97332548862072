var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.answerParts.length === 0)?[_c('v-row',{staticClass:"rounded mx-1 my-2 pa-2 d-flex justify-space-around",staticStyle:{"border":"1px solid gray"}},[_vm._v(" No answers added to this question yet. ")])]:[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex flex-row-reverse"},[(_setup.answerParts.length > 1)?_c('v-checkbox',{staticClass:"v-input--reverse",attrs:{"label":"Show partially correct submissions","disabled":_vm.disabled,"hide-details":""},model:{value:(_setup.partialCorrectness),callback:function ($$v) {_setup.partialCorrectness=$$v},expression:"partialCorrectness"}}):_vm._e()],1),(_setup.answerParts.length > 1)?_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('v-checkbox',{staticClass:"v-input--reverse",attrs:{"label":"Interchangeable answers","disabled":_vm.disabled || _setup.isInterchangeable,"hide-details":""},model:{value:(_setup.interchangeable),callback:function ($$v) {_setup.interchangeable=$$v},expression:"interchangeable"}})],1):_vm._e()]),_vm._l((_setup.answerParts),function(answerPart,partIndex){return _c('v-card',{key:partIndex,staticClass:"mt-4",attrs:{"color":"neutral lighten-2","elevation":"0"}},[(_setup.fillInAnswerTypes.includes(answerPart.answerType))?[_c('div',{key:partIndex,staticClass:"pa-4"},[_c('v-card-subtitle',{staticClass:"pa-0 pb-2"},[_vm._v(" Answer "+_vm._s(answerPart.htmlMarker)+" ")]),_vm._l((answerPart.answerValues),function(answerValue,valueIndex){return _c('div',{key:`${partIndex}-${valueIndex}`},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"messages":`${valueIndex > 0 ? 'alt ' : ''}Answer ${
                    answerPart.htmlMarker
                  }`,"background-color":"rgba(54, 109, 218, 0.08)","filled":"","disabled":_vm.disabled},on:{"blur":_setup.notifyUpdate},scopedSlots:_vm._u([(valueIndex > 0)?{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _setup.removeAnswerValueFromAnswerPart(partIndex, valueIndex)}}},[_vm._v(" mdi-close-circle ")])]},proxy:true}:null],null,true),model:{value:(answerValue.value),callback:function ($$v) {_vm.$set(answerValue, "value", $$v)},expression:"answerValue.value"}})],1),(
                  valueIndex === 0 &&
                  (!_setup.interchangeable || (_setup.interchangeable && partIndex === 0))
                )?_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_setup.answerTypes,"label":"Answer Type","background-color":"rgba(54, 109, 218, 0.08)","filled":"","hint":_setup.getAnswerTypeDescription(answerPart.answerType),"persistent-hint":"","menu-props":{ bottom: true, offsetY: true },"disabled":_vm.disabled},on:{"change":_setup.notifyUpdate},model:{value:(answerPart.answerType),callback:function ($$v) {_vm.$set(answerPart, "answerType", $$v)},expression:"answerPart.answerType"}})],1):_vm._e(),(
                  answerPart.answerValues?.length &&
                  valueIndex === answerPart.answerValues.length - 1
                )?_c('v-col',{staticClass:"d-flex align-center justify-space-around",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"primary","text":""},on:{"click":function($event){return _setup.addAnswerValueToAnswerPart(partIndex)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_setup.interchangeable ? 'Possible' : 'Correct')+" Answer ")],1)],1):_vm._e()],1)],1)})],2)]:(answerPart.answerType === _setup.AnswerType.DROP_DOWN)?[_c('v-card-text',{key:partIndex},[_c('v-card-subtitle',{staticClass:"pa-0 pb-2"},[_vm._v(" Dropdown "+_vm._s(answerPart.htmlMarker)+" ")]),_vm._l((answerPart.answerValues),function(answerValue,valueIndex){return _c('div',{key:valueIndex},[_c('v-row',{staticClass:"my-4",attrs:{"no-gutters":"","dense":"","align":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[(!_setup.isMultipleCorrect[partIndex])?_c('div',[_c('v-radio-group',{attrs:{"disabled":_vm.disabled},model:{value:(_setup.selectedAnswerIndex[partIndex]),callback:function ($$v) {_vm.$set(_setup.selectedAnswerIndex, partIndex, $$v)},expression:"selectedAnswerIndex[partIndex]"}},[_c('v-radio',{attrs:{"value":valueIndex,"hide-details":true,"color":"bluePrimary700"},on:{"change":function($event){return _setup.setCorrectAnswerPartValue(partIndex, valueIndex)}}})],1)],1):_c('div',[_c('v-checkbox',{attrs:{"input-value":answerValue.isCorrect,"disabled":_vm.disabled},on:{"change":function($event){return _setup.setCorrectAnswerPartValue(partIndex, valueIndex)}}})],1)]),_c('v-col',{attrs:{"cols":"10"}},[_c(_setup.BuilderEditor,{staticClass:"editor",attrs:{"isAnswerSetup":"","disabled":_vm.disabled},on:{"blur":_setup.notifyUpdate},model:{value:(answerValue.value),callback:function ($$v) {_vm.$set(answerValue, "value", $$v)},expression:"answerValue.value"}})],1),(
                  answerPart.answerValues?.length &&
                  answerPart.answerValues.length > 1
                )?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.disabled},on:{"click":function($event){return _setup.removeAnswerValueFromAnswerPart(partIndex, valueIndex)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1),_c('v-divider')],1)}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _setup.addAnswerValueToAnswerPart(partIndex)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Option ")],1),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"Multiple correct responses","disabled":_vm.disabled},on:{"change":function($event){return _setup.toggleMultipleCorrect($event, partIndex)}},model:{value:(_setup.isMultipleCorrect[partIndex]),callback:function ($$v) {_vm.$set(_setup.isMultipleCorrect, partIndex, $$v)},expression:"isMultipleCorrect[partIndex]"}})],1)],2)]:_vm._e()],2)})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }