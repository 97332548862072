var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"insights-hub-table neutral--text text--darken-4 text-center align-center",attrs:{"headers":_setup.tableHeaders,"items":_setup.itemRows,"custom-sort":_setup.customSort,"options":_vm.options,"loading":_vm.loading,"hide-default-header":"","hide-default-footer":"","disable-pagination":""},on:{"update:options":function($event){return _setup.updateOptions($event)},"current-items":function($event){return _setup.updateCsvData($event)}},scopedSlots:_vm._u([{key:"header",fn:function({ props, on }){return [_c('tr',{attrs:{"data-cy":"headers-row"}},_vm._l((props.headers),function(header){return _c('th',{key:header.value},[(header.value === _setup.StaticHeader.NAME)?_c('span',{staticClass:"px-6 d-flex align-center text-no-wrap text-subtitle-2 font-weight-medium text--darken-3",class:{
            clickable: !_vm.anonymized,
          },on:{"click":function($event){!_vm.anonymized && on.sort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),(!_vm.anonymized)?_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"neutral darken-3"}},[_vm._v(" mdi-sort ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.headerTitle.length < 20 ? `/ ${_vm.headerTitle}` : '')+" ")],1):(header.value === _vm.rowHeaders.averageHeader.value)?_vm._t("avg-header",function(){return [_c('span',{staticClass:"d-flex align-center justify-center text-caption clickable",on:{"click":function($event){return on.sort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"neutral darken-3"}},[_vm._v(" mdi-sort ")])],1)]},{"on":on,"header":header.value}):_vm._t("data-header",null,{"header":header})],2)}),0)]}},{key:`body.prepend`,fn:function({ headers }){return [_c('tr',{staticClass:"font-weight-bold",attrs:{"data-cy":"average-row"}},[_vm._l((headers),function(header){return [(header.value === _setup.StaticHeader.NAME)?_c('td',{key:header.value,staticClass:"text-caption text-right",attrs:{"data-cy":"average-row-name-cell"}},[_vm._v(" "+_vm._s(_setup.averageRow[_setup.StaticHeader.NAME])+" ")]):[_c('td',{key:header.value,staticClass:"pa-0 ma-0",class:{
              'not-clickable': _vm.disablePopoutGraphs,
            },attrs:{"data-cy":"average-row-item-cell"}},[(_setup.averageRow[header.value])?_vm._t("data-cell",null,{"gradient":_setup.convertHexToRGBA(
                  _setup.getTableColor(header.value),
                  _setup.averageRow[header.value].numerator /
                    _setup.averageRow[header.value].denominator,
                  _setup.highestTableValue
                ),"percentage":_setup.getValue(_setup.averageRow[header.value]),"item":_setup.averageRow,"header":header}):_vm._t("empty-cell",null,{"header":header})],2)]]})],2)]}},{key:"item",fn:function({ item, headers }){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_setup.hiddenPaths.includes(item.path)),expression:"!hiddenPaths.includes(item.path)"}],attrs:{"data-cy":"item-row"}},[_vm._l((headers),function(header){return [(header.value === _setup.StaticHeader.NAME)?_c('td',{key:header.value,staticClass:"text-body-2 text-left",attrs:{"data-cy":"item-row-name-cell"}},[(
              item.type === _setup.RowType.SCHOOL ||
              item.type === _setup.RowType.TEACHER ||
              (item.type === _setup.RowType.COURSE && _setup.dataIncludesStudents)
            )?_c('div',{staticClass:"d-flex align-center clickable",class:{
              'ml-5': item.type === _setup.RowType.TEACHER,
              'ml-10': item.type === _setup.RowType.COURSE,
            },on:{"click":function($event){return _setup.handleRowClick(item)}}},[(Array.from(_vm.collapsedPaths).includes(item.path))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-up")]),(item.type === _setup.RowType.SCHOOL)?_c('span',{staticClass:"school-name-cell ml-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'SXXXXX' : item[_setup.StaticHeader.NAME])+" ")]):(item.type === _setup.RowType.TEACHER)?_c('span',{staticClass:"teacher-name-cell ml-2"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'TXXXXX' : item[_setup.StaticHeader.NAME])+" ")]):(item.type === _setup.RowType.COURSE)?_c('span',{staticClass:"course-name-cell"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'CXXXXX' : item[_setup.StaticHeader.NAME])+" ")]):_vm._e()],1):(item.type === _setup.RowType.COURSE && !_setup.dataIncludesStudents)?_c('div',{staticClass:"course-name-cell text-right"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'CXXXXX' : item[_setup.StaticHeader.NAME])+" ")]):_c('div',{staticClass:"student-name-cell text-right"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'STXXXXX' : item[_setup.StaticHeader.NAME])+" ")])]):[_c('td',{key:header.value,staticClass:"pa-0 ma-0",class:{
              'font-weight-bold':
                header.value === _vm.rowHeaders.averageHeader.value,
              'mr-2': header.value === _vm.rowHeaders.averageHeader.value,
              'mt-2': item.type === _setup.RowType.SCHOOL,
              'not-clickable': !item[header.value],
            },attrs:{"data-cy":"item-row-item-cell"},on:{"click":function($event){return _setup.handleMapRowClick(
                item,
                item[header.value],
                header.value,
                $event
              )}}},[(item[header.value])?_vm._t("data-cell",null,{"gradient":_setup.convertHexToRGBA(
                  _setup.getTableColor(header.value),
                  item[header.value].numerator /
                    item[header.value].denominator,
                  _setup.highestTableValue
                ),"percentage":_setup.getValue(item[header.value]),"item":item,"header":header}):_vm._t("empty-cell",null,{"dataCy":"item-row-empty-cell","header":header})],2)]]})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }