import { render, staticRenderFns } from "./ProblemTypeFilter.vue?vue&type=template&id=0a22f288&scoped=true&"
import script from "./ProblemTypeFilter.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProblemTypeFilter.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProblemTypeFilter.vue?vue&type=style&index=0&id=0a22f288&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a22f288",
  null
  
)

export default component.exports