var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":"","data-cy":"assignment-details"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex flex-column align-center mt-2"},[_c('h2',{staticClass:"text-h2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.stats.numCompleted)+" / "+_vm._s(_vm.stats.numAssigned)+" ")]),_c('div',{staticClass:"caption mb-4 font-weight-medium"},[_vm._v("Completed")]),_c('v-btn',{attrs:{"outlined":"","color":"primary","to":{
            name: 'ReportLandingPage',
            params: {
              xref: `${_vm.assignment.xref}`,
            },
            query: {
              ..._setup.route.query,
              [_setup.RETURN_URL]: _setup.router.currentRoute.fullPath,
              [_setup.RETURN_TEXT]: 'assignment',
            },
          }}},[_vm._v(" View Report ")])],1)]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('div',{staticClass:"details"},[_c('div',{staticClass:"text-overline detail-category"},[_vm._v("# of Problems")]),_c('div',{staticClass:"ml-4 mb-4"},[_vm._v(_vm._s(_vm.stats.numProblems))]),(_setup.timeLimitInMinutes)?_c('div',[_c('div',{staticClass:"text-overline detail-category"},[_vm._v("Time Limit")]),_c('div',{staticClass:"ml-4 mb-4",attrs:{"data-cy":"time-limit-amount"}},[_vm._v(" "+_vm._s(_setup.timeLimitInMinutes)+" minutes ")])]):_vm._e(),_c('div',{staticClass:"text-overline detail-category"},[_vm._v("Release Date")]),_c('div',{staticClass:"ml-4 mb-4"},[_vm._v(_vm._s(_setup.releaseDate))]),_c('div',{staticClass:"text-overline detail-category"},[_vm._v("Due Date")]),_c('div',{staticClass:"ml-4 mb-4"},[_vm._v(_vm._s(_setup.dueDate))]),_c('div',{staticClass:"text-overline detail-category"},[_vm._v("Standards")]),(_setup.matchedSkills.length > 0)?_vm._l((_setup.matchedSkills),function(skill){return _c('div',{key:skill.xref,staticClass:"ml-4 mb-2"},[_vm._v(" "+_vm._s(skill.nodeCode)+" - "+_vm._s(skill.nodeName)+" ")])}):_c('span',{staticClass:"ml-4 mb-2"},[_vm._v(_vm._s(_setup.placeholder))])],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }