import { render, staticRenderFns } from "./SupportsView.vue?vue&type=template&id=0a1cf8d7&scoped=true&"
import script from "./SupportsView.vue?vue&type=script&lang=ts&setup=true&"
export * from "./SupportsView.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a1cf8d7",
  null
  
)

export default component.exports