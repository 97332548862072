import { render, staticRenderFns } from "./AddProblemSetDialog.vue?vue&type=template&id=2ebe2a7d&scoped=true&"
import script from "./AddProblemSetDialog.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AddProblemSetDialog.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./AddProblemSetDialog.vue?vue&type=style&index=0&id=2ebe2a7d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ebe2a7d",
  null
  
)

export default component.exports