import { render, staticRenderFns } from "./ProblemRowView.vue?vue&type=template&id=fed82ec2&scoped=true&"
import script from "./ProblemRowView.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProblemRowView.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProblemRowView.vue?vue&type=style&index=0&id=fed82ec2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed82ec2",
  null
  
)

export default component.exports