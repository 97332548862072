import { render, staticRenderFns } from "./DeleteAssignmentDialog.vue?vue&type=template&id=7714e80d&scoped=true&"
import script from "./DeleteAssignmentDialog.vue?vue&type=script&lang=ts&setup=true&"
export * from "./DeleteAssignmentDialog.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./DeleteAssignmentDialog.vue?vue&type=style&index=0&id=7714e80d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7714e80d",
  null
  
)

export default component.exports