import { render, staticRenderFns } from "./CopyProblemDialog.vue?vue&type=template&id=6be9bec0&scoped=true&"
import script from "./CopyProblemDialog.vue?vue&type=script&lang=ts&setup=true&"
export * from "./CopyProblemDialog.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be9bec0",
  null
  
)

export default component.exports