import { render, staticRenderFns } from "./LessonDetailsTable.vue?vue&type=template&id=06f74586&scoped=true&"
import script from "./LessonDetailsTable.vue?vue&type=script&lang=ts&setup=true&"
export * from "./LessonDetailsTable.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./LessonDetailsTable.vue?vue&type=style&index=0&id=06f74586&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f74586",
  null
  
)

export default component.exports